import logo from './logo.svg';
import './App.css';
import Tips from './Tips';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

  return (
    <Elements stripe={stripePromise}>
    <BrowserRouter>
    <Routes>
        <Route
            path="/:order"
            element={
                <Tips>
                </Tips>
            }
        />
       
    </Routes>
</BrowserRouter>
</Elements>
  );
}

export default App;
