// Loader.js
import React from 'react';
import styled from 'styled-components';





const LoaderOverlay = styled.div`
  position: fixed;\
  font-size:50px;
  color:white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
`;

const Loader = () => {



  return (
    (

  
      <LoaderOverlay>
       <div className="loader">Loading...</div>
      

      </LoaderOverlay>
    )
  )
};

export default Loader;