import React, { useEffect, useState } from 'react';
import './index.css';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";


function Tips() {
  const [tipAmount, setTipAmount] = useState('');
  const [loading, setLoading] = useState(true)
  const [object, setObject] = useState({})
  const { order } = useParams();
  const stripe = useStripe();
  const elements = useElements();

  const handleTipInputChange = (e) => {
    setTipAmount(e.target.value);
  };

  const handleTipSubmit = () => {
    setLoading(true)
    // Here you can add your logic to handle the submitted tip amount
    console.log('Submitted tip amount:', tipAmount, process.env.REACT_APP_BACKEND_URL);

    fetch(process.env.REACT_APP_BACKEND_URL + `/payments/pay-tip`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // Specify the content type as JSON
      },
      body: JSON.stringify({ amount: tipAmount, order: object?.data?._id, specialist: object?.data?.specialist?._id, client: object?.data?.client })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the JSON response
      })
      .then(async data => {
        
        // window.location.reload();
        // Handle the response data

        const { paymentIntent, error } = await stripe.confirmCardPayment(
          data.paymentIntent.client_secret,
          { payment_method: data.paymentIntent.payment_method }
      );

      if(paymentIntent?.status==='succeeded'){
        fetch(process.env.REACT_APP_BACKEND_URL + `/payments/confirm-tip`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // Specify the content type as JSON
          },
          body: JSON.stringify({ amount: tipAmount, order: object?.data?._id, specialist: object?.data?.specialist?._id, client: object?.data?.client })
        }).then(async data => {
          setLoading(false)
 window.location.reload();

        })
      }

      console.log('asdlkjaskdhasjkdhasjkd', data, paymentIntent)

        setObject(data)
        return data
      })
      .catch(error => {
        setLoading(false)
        // Handle errors
        console.error('There was a problem with your fetch operation:', error);
      });


  }


  useEffect(() => {
    if (order) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/orders/${order}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json' // Specify the content type as JSON
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse the JSON response
        })
        .then(data => {
          setLoading(false)
          // Handle the response data
          console.log('asdlkjaskdhasjkdhasjkd', data)
          setObject(data)
          return data
        })
        .catch(error => {
          // Handle errors
          console.error('There was a problem with your fetch operation:', error);
        });


    }
  }, [order])

  return (
    <div className="App">
      {
        loading && (
          <Loader />
        )
      }
      {
        (!loading && !object?.data?.isTipped) ? (<div className="tip-container">
          <h1>Let's Appreciate {object?.data?.specialist?.user?.firstName} with Tip</h1>
          <input
            type="number"
            placeholder="Enter tip amount"
            value={tipAmount}
            onChange={handleTipInputChange}
          />
          <button onClick={handleTipSubmit}>Tip now</button>
        </div>) : ""
      }
      {
        (!loading && object?.data?.isTipped) ? (<div className="tip-container">
          <h1>You have tipped to {object?.data?.specialist?.user?.firstName} Successfully!</h1>
          
        </div>) : ""
      }
    </div>
  );
}

export default Tips;